<template>
  <div>
    <!-- appbar -->
    <v-app-bar
        app
        color="primary"
    >
      <v-app-bar-nav-icon
          color="white"
          @click="
          !$vuetify.breakpoint.xsOnly ? (mini = !mini) : (drawer = !drawer)
        "
      />

      <div class="ml-auto d-flex align-center">
        <v-select
            v-if="userInfo"
            dense
            solo
            label="Ρόλος"
            :value="this.selectedRole"
            placeholder="Ρόλος"
            :items="this.userInfo.userInfo.roles"
            item-text="description"
            item-value="id"
            class="roleSelected"
            hide-details="auto"
            @change="this.onSetSelectedRole"
        />

        <v-menu
            offset-y
            transition="scroll-y-transition"
            class="menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                icon
                color="white"
                v-bind="attrs"
                v-on="on"
            >
              <v-badge
                  :content="unreadNotifications.elements"
                  :value="unreadNotifications.elements"
                  color="success"
                  overlap
              >
                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <div
              class="pa-3 info text-body-1 white--text menu d-flex flex-column"
          >
            <span
                v-if="
                unreadNotifications.elements > 1 ||
                  unreadNotifications.elements === 0
              "
                class="font-weight-bold white--text"
            >{{ unreadNotifications.elements }}
              {{ $store.getters.locales['news'] }}
            </span>

            <span
                v-else-if="unreadNotifications.elements === 1"
                class="font-weight-bold white--text"
            >{{ unreadNotifications.elements }} {{ $store.getters.locales['new'] }}
            </span>

            <v-btn
                v-if="
                unreadNotifications.elements > 1 ||
                  unreadNotifications.elements === 0
              "
                plain
                text
                dense
                class="text-body-2 white--text pa-0"
                @click="$router.push('/notification')"
            >
              <span class="mr-auto">{{ $store.getters.locales['notification'] }}</span>
            </v-btn>

            <v-btn
                v-else-if="unreadNotifications.elements === 1"
                text
                dense
                plain
                class="text-body-2 white--text pa-0"
                @click="$router.push('/notification')"
            >
              <span class="mr-auto">{{ $store.getters.locales['notification'] }}</span>
            </v-btn>
          </div>
          <v-list dense>
            <v-list-item
                v-for="(n, i) in unreadNotifications.content"
                :key="i"
                link
                active-class="primary--text"
                @click="$router.push('/notification')"
            >
              <v-list-item-title>{{ n.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
            offset-y
            transition="scroll-y-transition"
            class="menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                large
                icon
                color="white"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>

          <div
              v-if="userInfo.userInfo.person && selectedRole != 7"
              class="pa-3 info text-body-1 white--text menu d-flex flex-column"
          >
            <span
                class="font-weight-bold"
            >{{ userInfo.userInfo.person.firstName }}
              {{ userInfo.userInfo.person.lastName }}</span>

            <span class="text-body-2"> {{ $store.getters.locales['userEmail'] }}</span>
          </div>

          <v-list dense>
            <v-list-item
                v-if="selectedRole != 7"
                link
                active-class="primary--text"
                to="/profile"
            >
              <v-icon>mdi-account-outline</v-icon>
              <v-list-item-title>{{ $store.getters.locales['profile'] }}</v-list-item-title>
            </v-list-item>

            <v-list-item
                class="text--red"
                link
                active-class="primary--text"
                @click="onLogout"
            >
              <v-icon>mdi-power</v-icon>
              <v-list-item-title> {{ $store.getters.locales['logout'] }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <!-- appbar -->

    <!-- navdrawer -->
    <v-navigation-drawer
        v-model="drawer"
        :width="320"
        :permanent="!$vuetify.breakpoint.xsOnly"
        :mini-variant="!$vuetify.breakpoint.xsOnly ? mini : false"
        :expand-on-hover="mini"
        app
    >
      <v-list-item
          class="px-2 list-height"
          @click="goToHome()"
      >
        <v-list-item-avatar class="mr-2">
          <v-img src="@/assets/ethnosimo.png" />
        </v-list-item-avatar>

        <v-list-item-title class="greyish--text text-no-wrap">
          {{ $store.getters.locales['loginLabel'] }}
        </v-list-item-title>
      </v-list-item>

      <v-divider />
      <v-list dense>
        <div v-if="userInfo.userInfo.roles">
          <div
              v-for="item in this.selectedMenu"
              :key="item.name"
          >
            <v-list-item
                v-if="item.isParent === 0"
                :key="item.name"
                link
                :to="item.path"
                color="primary"
            >
              <v-icon class="mr-2">
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$store.getters.locales[item.name]" />
              </v-list-item-content>
            </v-list-item>

            <v-list-group
                v-else
                v-model="item.active"
                :prepend-icon="item.action"
                no-action
            >
              <template v-slot:activator>
                <v-icon class="mr-2">
                  {{ item.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="$store.getters.locales[item.name]" />
                </v-list-item-content>
              </template>
              <div
                  v-for="child in item.childMenus"
                  :key="child.name"
              >
                <v-list-item
                    v-if="child.isParent === 0"
                    :key="child.name"
                    link
                    :to="child.path"
                    class="childMenu"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon class="links">
                      {{ child.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> {{ $store.getters.locales[child.name] }}</v-list-item-title>
                </v-list-item>
                <v-list-group
                    v-else
                    v-model="child.active"
                    :prepend-icon="child.action"
                    no-action
                    sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                          v-text="child.name"
                      />
                    </v-list-item-content>
                  </template>

                  <div
                      v-for="subChild in child.childMenus"
                      :key="subChild.name"
                  >
                    <v-list-item
                        :key="subChild.name"
                        link
                        :to="subChild.path"
                    >
                      <v-list-item-icon class="mr-2">
                        <v-icon class="links">
                          {{ subChild.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ subChild.name }}</v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list-group>
              </div>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
    <!-- navdrawer -->
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";

export default {

  data() {
    return {
      messages: 1,
      drawer: false,
      mini: false
    };
  },
  async created() {
    await this.getUnreadNotifications({
      page: 1,
      limit: 10,
    });
  },
  computed: {
    ...mapState({
      unreadNotifications: (state) => state.notifications.unreadNotifications,
      userProfile: (state) => state.users.userProfile,
      userInfo: (state) => state.auth,
      selectedRole: (state) => state.auth.selectedRole
    }),
    ...mapGetters({isLoggedIn:["auth/isLoggedIn"]}),
    selectedMenu() {
      try {
        let menu = this.userInfo.userInfo.roles.filter((m) => {
          if (m.id === this.selectedRole) {
            return m.menus;
          }
        });
        if (menu.length > 0) {
          return menu[0].menus;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    },
  },

  methods: {
    ...mapActions(["logout", "getUnreadNotifications"]),

    onLogout() {
      this.logout();
      this.$router.push("/login/");
    },
    onSetSelectedRole(value) {
      let page = this.userInfo.userInfo.roles.filter((m) => {
        if (m.id === value) {
          return m;
        }
      });

      this.$store
          .dispatch("auth/setSelectedRole", {
            roleId: value,
            name: page[0].systemRole,
          })
          .then(() => {
            this.$router.push(page[0].defaultPage);
          });
    },
    goToHome() {
      let page = this.userInfo.userInfo.roles.filter((m) => {
        if (m.id === this.selectedRole) {
          return m;
        }
      });
      this.$router.push(page[0].defaultPage);
    },
  },
};
</script>

<style scoped lang="scss">
.list-height {
  height: 64px !important;
}

.avatar-image {
  width: 31px !important;
  height: 31px !important;
}

.menu {
  width: 350px !important;
}

.roleSelected {
  font-size: small !important;
}

.childMenu {
  margin-left: 25px;
}

</style>
