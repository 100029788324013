import axiosClient from "@/service/client/AxiosClientToken";
import {create, get, getForm, remove, update} from "@/service/Services";

const resource = "/menu/role";

export default {
    get: (params) => get(axiosClient, params, resource),
    getForm: (id) => getForm(axiosClient, id, resource),
    create: (payload) => create(axiosClient, payload, resource),
    update: (payload, id) => update(axiosClient, payload, id, resource),
    delete: (id) => remove(axiosClient, id, resource),
};
