import i18n from "@/i18n/i18n";

export default {
    state: {
        GR: {},
        EN: {},
    },
    mutations: {},
    actions: {},
    getters: {
        locales: (state) => state[i18n.locale],
    },
};
