import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import store from "@/store";

Vue.use(VueI18n);

function loadLocaleMessages() {
    let messages = {};
    axios.get(`./locales/GR.json`).then((response) => {
        messages.GR = response.data;
        store.state.locale.GR = response.data;
        return messages.GR;
    });
    axios.get(`./locales/EN.json`).then((response) => {
        messages.EN = response.data;
        store.state.locale.EN = response.data;
        return store.state.locale.GR;
    });
    return messages;
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "GR",
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE || "EN",
    messages: loadLocaleMessages(),
});
