export default {
    namespaced: true,
    state: {
        allActions: [],
    },

    mutations: {
        SET_ALL_ACTIONS(state, payload) {
            state.allActions = payload;
        },
    },

    actions: {
        setAllActions({commit}, payload) {
            commit("SET_ALL_ACTIONS", payload);
        },
    },
    getters: {
        getAllActions: (state) => state.allActions,
    },
};
