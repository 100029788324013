import axios from "axios";
import store from "@/store/index";

const baseDomain = `${process.env.VUE_APP_BASE_URL}`;
const baseURL = `${baseDomain}`;

/*
 * https://blog.clairvoyantsoft.com/intercepting-requests-responses-using-axios-df498b6cab62.
 */
// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const AxiosClient = axios.create({
    baseURL: baseURL,
    timeout: 200000,
    headers: {
        "Content-Type": "application/json",
    },
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
    // Token will be dynamic so we can use any app-specific way to always
    // fetch the new token before making the call
    return request;
};

const responseHandler = (response) => {
    return response;
};

const errorHandler = (error) => {
    if (error.response.status === 401) {
        store.dispatch("errorHandler", error).then(() => {
            /* TODO document why this arrow function is empty */
        });
    }
    return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
AxiosClient.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

AxiosClient.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default AxiosClient;
