import axiosClientToken from "@/service/client/AxiosClientToken";
import {create, get, getForm, remove, update} from "@/service/Services";

const resource = "/person/application";

export default {
    get: (params) => get(axiosClientToken, params, resource),
    getForm: (id) => getForm(axiosClientToken, id, resource),
    create: (payload) => create(axiosClientToken, payload, resource),
    update: (payload, id) => update(axiosClientToken, payload, id, resource),
    delete: (id) => remove(axiosClientToken, id, resource),
    approveApplication: async (id, formData) => {
        return axiosClientToken.post(`${resource}/${id}/approve`, formData);
    },
    temporaryApplication: async (id, formData) => {
        return axiosClientToken.post(`${resource}/${id}`, formData);
    },
    getByPerson: async (params) => {
        return axiosClientToken.get(`${resource}/list/user`, params);
    },
    pendingApplication: async (id, data) => {
        return axiosClientToken.put(`${resource}/${id}/pending`, data);
    },
};
