import axios from "axios";
import store from "@/store/index";

const baseDomain = `${process.env.VUE_APP_BASE_URL}`;
const baseURL = `${baseDomain}`; // Incase of /api/v1;

/*
 * https://blog.clairvoyantsoft.com/intercepting-requests-responses-using-axios-df498b6cab62.
 */
// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const AxiosClientToken = axios.create({
    baseURL: baseURL,
    timeout: 72000,
    headers: {
        "Content-Type": "application/json",
    },
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
    // Token will be dynamic so we can use any app-specific way to always
    // fetch the new token before making the call
    request.headers.Authorization = `Bearer ${store.getters['auth/getToken']}`;
    return request;
};

const responseHandler = (response) => {
    if (response.status === 401) {
        window.location = "/login";
    }
    return response;
};

const errorHandler = (error) => {
    if (
        [400, 401, 404, 409, 500, 470, 471, 472, 473, 476, 477].includes(
            error.response.status
        )
    ) {
        store.dispatch("errorHandler", error).then(() => {
        });
    }
    return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
AxiosClientToken.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

AxiosClientToken.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default AxiosClientToken;
