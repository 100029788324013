import errors from "../../errors.json";

export default {
    state: {
        errors: null,
    },

    mutations: {
        getErrors(state, errors) {
            state.errors = errors;
        },
    },

    actions: {
        getErrors({commit}) {
            commit("getErrors", errors);
        },

        errorHandler({commit, state}, error) {
            if (error?.response?.data?.code?.toString() in state.errors) {
                commit("toggleSnackbar", {
                    open: true,
                    text: `${state.errors[error?.response?.status?.toString()]}`,
                    color: "error",
                });
            } else {
                commit("toggleSnackbar", {
                    open: true,
                    text: `${error?.response?.data?.error}`,
                    color: "error",
                });
            }
        },
    },
};
