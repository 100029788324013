import axiosClientToken from "@/service/client/AxiosClientToken";
import {create, get, getForm, remove} from "@/service/Services";

const resource = "/report";

export default {
    get: (params) => get(axiosClientToken, params, resource),
    getForm: (id) => getForm(axiosClientToken, id, resource),
    create: (payload) => create(axiosClientToken, payload, resource),
    update: async (payload, id) => {
        return axiosClientToken.post(`${resource}/${id}`, payload);
    },
    delete: (id) => remove(axiosClientToken, id, resource),
    getByCategory: () => {
        return axiosClientToken.get(`${resource}/list/by/category`);
    },
};
