import axiosClient from "@/service/client/AxiosClientToken";
import {create, get, getForm, update} from "@/service/Services";

const resource = "/person";

export default {
    get: (params) => get(axiosClient, params, resource),
    getForm: (id) => getForm(axiosClient, id, resource),
    create: (payload) => create(axiosClient, payload, resource),
    update: (payload, id) => update(axiosClient, payload, id, resource),
    delete: async (payload) => {
        return axiosClient.delete(
            `${resource}/role/remove?personId=${payload.personId}&roleId=${payload.roleId}`
        );
    },
    addRole: async (payload) => {
        return axiosClient.post(
            `${resource}/role/add?personId=${payload.personId}&roleId=${payload.roleId}`
        );
    },
    getSailor: async (payload) => {
        return axiosClient.get(`${resource}/sailors/list`, {params: payload});
    },
    getOperators: async (payload) => {
        return axiosClient.get(`${resource}/operators/list`, {params: payload});
    },
    getStoreDetails: async (personId) => {
        return axiosClient.get(`/store/details/${personId}`);
    },
    getPersonRoles: async (personId) => {
        return axiosClient.get(`${resource}/${personId}/roles`);
    },
};
