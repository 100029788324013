export async function get(axiosClient, params, resource) {
    return axiosClient.get(`${resource}/list`, {params: params});
}

export async function getForm(axiosClient, id, resource) {
    return axiosClient.get(`${resource}/${id}`);
}

export async function create(axiosClient, payload, resource) {
    return axiosClient.post(`${resource}`, payload);
}

export async function update(axiosClient, payload, id, resource) {
    return axiosClient.put(`${resource}/${id}`, payload);
}

export async function remove(axiosClient, id, resource) {
    return axiosClient.delete(`${resource}/${id}`);
}

export async function call(axiosClient, lovGroupId, resource) {
    if (lovGroupId instanceof Array) {
        const promises = lovGroupId.map((id) =>
            axiosClient.get(`${resource}/call/${id}`)
        );
        return Promise.all([...promises]).then(function (values) {
            return values;
        });
    } else {
        let {data} = await axiosClient.get(`${resource}/call/${lovGroupId}`);
        return data.content;
    }
}

export async function downloadCsv(axiosClient, commonFormId, resource) {
    return axiosClient
        .get(`${resource}/${commonFormId}/download/csv`)
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response.data], {type: "text/csv"})
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "template.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
}

export async function uploadCsv(axiosClient, commonFormId, formData, resource) {
    return axiosClient.post(`${resource}/${commonFormId}/upload/csv`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
