import axios from "@/service/client/AxiosClientToken";

export default {
    actions: {
        async downloadFile({dispatch}, id) {
            try {
                const res = await axios({
                    method: "get",
                    url: `${process.env.VUE_APP_BASE_URL}/file/${id}`,
                    responseType: "arraybuffer",
                });

                let filename = res.headers["content-disposition"]
                    .split("filename=")[1]
                    .split(";")[0];
                let blob = new Blob([res.data], {type: res.headers.contentType});
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                if (navigator.userAgent.includes("Firefox")) {
                    link.target = decodeURI(filename);
                } else {
                    link.download = decodeURI(filename);
                }
                link.click();
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    },
};
