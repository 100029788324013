import Repository from "@/service/Repository";

const LovGroupRepo = Repository.get("lovGroup");

export default {
    namespaced: true,
    state: {
        dynamicView: [],
    },

    mutations: {
        UPDATE_DYNAMIC_VIEW(state, payload) {
            state.dynamicView = payload;
        },
        CLEAR_DYNAMIC_VIEW(state) {
            state.dynamicView = [];
        },
    },

    actions: {
        async clearDynamicList({commit}) {
            commit("CLEAR_DYNAMIC_VIEW");
        },

        async updateDynamicListWithRepo({commit}, repo) {
            commit("CLEAR_DYNAMIC_VIEW");
            const lovList = await repo;
            await commit("UPDATE_DYNAMIC_VIEW", lovList.data.content);
        },

        async updateDynamicListWithData({commit}, data) {
            commit("CLEAR_DYNAMIC_VIEW");
            await commit("UPDATE_DYNAMIC_VIEW", data);
        },

        async updateDynamicList({commit}, groupLovId) {
            commit("CLEAR_DYNAMIC_VIEW");
            let lovList = [];
            let res = await LovGroupRepo.call(groupLovId.list);
            for (const item of res) {
                let data = item.data.content;
                if (!lovList.includes(data)) {
                    lovList.push(...data);
                }
            }

            if (groupLovId.repo !== null && groupLovId.repo !== undefined) {
                const response = await groupLovId.repo;
                let items = response.data.content;
                if (!lovList.includes(items)) {
                    lovList.push(...items);
                }
            }
            if (lovList.length > 0) {
                commit("UPDATE_DYNAMIC_VIEW", lovList);
            }
        },
    },
    getters: {
        getDynamicView: (state) => () => state.dynamicView,
        getDynamicViewLovId: (state) => (lovId) => {
            return (lovId != null && lovId != undefined) ? state.dynamicView.filter(row => row.lovGroupId == lovId) : state.dynamicView
        },
    },
};
