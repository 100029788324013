import axios from "axios";
import Repository from "@/service/Repository";

export default {
    state: {
        notifications: [],
        unreadNotifications: [],

        //dropdowns
        notificationStatus: [],
        notificationCategories: []
    },
    mutations: {
        GET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications
        },

        GET_UNREAD_NOTIFICATIONS(state, notifications) {
            state.unreadNotifications = notifications;
        },

        //dropdowns
        getNotificationStatus(state, status) {
            state.notificationStatus = status
        },

        getNotificationCategories(state, categories) {
            state.notificationCategories = categories
        }
    },
    actions: {
        async getNotifications({commit, dispatch}, payload) {
            try {
                const NotificationRepo = Repository.get("notification");
                payload.read = 1
                const res = await NotificationRepo.getNotifications(payload);
                commit("GET_NOTIFICATIONS", res.data);
            } catch (e) {
                dispatch("errorHandler", e);
                throw e;
            }
        },

        async getUnreadNotifications({commit, dispatch}, payload) {
            try {
                const NotificationRepo = Repository.get("notification");
                payload.read = 0
                const res = await NotificationRepo.getNotifications(payload);
                commit("GET_UNREAD_NOTIFICATIONS", res.data);
            } catch (e) {
            }
        },

        async readNotification({dispatch}, id) {
            try {
                const NotificationRepo = Repository.get("notification");
                await NotificationRepo.readNotification(id);
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
        async deleteNotification({commit, dispatch, rootState, state}, payload) {
            try {
                await axios.delete(
                    `${process.env.VUE_APP_BASE_URL}/notification/${payload.id}`,
                    payload
                );
                await dispatch("getUnreadNotifications", {
                    page: 1,
                    limit: 10,
                })
                if (state.notifications.limit == 1 && state.notifications.page != 1) {
                    commit('changePage', true)
                }
                await dispatch("getNotifications", {
                    page: state.notifications.limit == 1 ? rootState.base.filters.page - 1 : rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage,
                })

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή ειδοποίησης ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        ////dropdowns used only in AddNotification component
        async addNotification({commit, dispatch, rootState}, payload) {
            try {
                await axios.post(
                    `${process.env.VUE_APP_BASE_URL}/notification`,
                    payload
                );

                await dispatch("getUnreadNotifications", {
                    page: 1,
                    limit: 10,
                });
                await dispatch("getNotifications", {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage,
                })

                commit('toggleSnackbar', {
                    open: true,
                    text: "Η δημιουργία ειδοποίησης ολοκληρώθηκε με επιτυχία",
                    color: "success",
                });
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getNotificationStatus({commit}) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/notification/category/list`, {
                    params: {
                        page: 0,

                    }
                })
                commit('getNotificationStatus', res.data)
            } catch (e) {
                throw e;
            }
        },

        async getNotificationCategories({commit}) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/notification/status/list`, {
                    params: {
                        page: 0,

                    }
                })
                commit('getNotificationCategories', res.data)
            } catch (e) {
                throw e;
            }
        },
    }
}