export default {
    namespaced: true,
    state: {
        personId: null,
        person: null,
        supportCertification: true,
        supportAddress: true,
        supportAuthDocs: true,
    },
    mutations: {
        SET_EDIT_PERSON_VALUE(state, payload) {
            state.personId = payload.person.id;
            state.person = payload.person;
            state.supportCertification = payload.supportCertification;
        },
    },
    actions: {
        setEditPersonValue({commit}, payload) {
            commit("SET_EDIT_PERSON_VALUE", payload);
        },
    },
    getters: {
        getPersonId: (state) => state.personId,
    },
};
