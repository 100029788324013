import axiosClient from "@/service/client/AxiosClientToken";
import {create, get} from "@/service/Services";

const resource = "/person/health";

export default {
    get: (params) => get(axiosClient, params, resource),
    update: (payload) => {
        return axiosClient.put(`${resource}/update`, payload);
    },
    create: (payload) => create(axiosClient, payload, resource),

    getApproved: async (payload) => {
        return axiosClient.get(`${resource}/list/approved`, {params: payload});
    },
};
