import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import i18n from "./i18n/i18n.js";
import axios from "axios";

Vue.use(Vuelidate);

Vue.config.productionTip = false;

if (store.getters['auth/isLoggedIn']) {
    axios.defaults.headers.common[
        "Authorization"
        ] = `Bearer ${store.state.auth.token}`;
}
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
