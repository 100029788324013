import axiosClientToken from "@/service/client/AxiosClientToken";
import {create, get, getForm, remove, update} from "@/service/Services";

const resource = "/person/address";

export default {
    get: (params) => get(axiosClientToken, params, resource),
    getForm: (id) => getForm(axiosClientToken, id, resource),
    create: (payload) => create(axiosClientToken, payload, resource),
    update: (payload, id) => update(axiosClientToken, payload, id, resource),
    delete: (id) => remove(axiosClientToken, id, resource),
};
