import Client from "@/service/client/AxiosClient";
import ClientToken from "@/service/client/AxiosClientToken";

export default {
    async loginLdap(payload) {
        return Client.post(`/ldap/login`, payload);
    },
    loginTaxis() {
        return ClientToken.get(`/after/taxis/login`);
    },
    refreshToken() {
        return ClientToken.get(`/refresh`);
    },
    changeRole(payload) {
        return ClientToken.get(`/change/role`, payload);
    },
    changePassword(payload) {
        return ClientToken.put(`/ldap/change/password`, payload);
    },
};
