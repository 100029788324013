import axiosClientToken from "@/service/client/AxiosClientToken";

const resource = "/jasper/download";

export default {
    download: async (payload, id) => {
        return axiosClientToken.post(`${resource}?outputType=${id}`, payload, {
            responseType: "blob"
        });
    },
};
