import Repository from "@/service/Repository";
import store from "@/store";

const PersonApplication = Repository.get("personApplication");
export default {
    state: {
        isTemporaryApplication: false,
    },

    mutations: {
        IS_TEMPORARY(state, isTemporary) {
            state.isTemporaryApplication = isTemporary;
        },
    },

    actions: {
        isTemporaryApplication({commit}, isTemporary) {
            commit("IS_TEMPORARY", isTemporary);
        },

        async submitApplication({commit, dispatch, rootState, state}, payload) {
            let response;
            if (state.isTemporaryApplication) {
                response = await PersonApplication.temporaryApplication(payload.id, payload.formData);
            } else {
                response = await PersonApplication.create(payload.formData);
            }
            if ([200, 201, 202].includes(response.status)) {
                await toggleSnackbarByApproveFlag(payload, response)
            }
        },
    },
};
const toggleSnackbarByApproveFlag = async (payload, response) => {
    if (payload.formData.get("flgApprove") != 3) {
        await store.dispatch("toggleSnackbar", {
            open: true,
            text: `Η υποβολή με αριθμό:${response.data.content.protocolNo} ολοκληρώθηκε με επιτυχία.`,
            color: "success",
        })
    } else {
        await store.dispatch("toggleSnackbar", {
            open: true,
            text: "Η αποθήκευση ολοκληρώθηκε με επιτυχία.",
            color: "success",
        })
    }
}
