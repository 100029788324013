export default {
    namespaced: true,
    state: {
        formId: null,
        formSetupDescription: null,
        formCategoryDescription: null,
    },
    mutations: {
        SET_EDIT_FORM(state, payload) {
            state.formId = payload.formId;
            state.formSetupDescription = payload.formSetupDescription;
            state.formCategoryDescription = (payload.formCategoryDescription != null || payload.formCategoryDescription != undefined) ? payload.formCategoryDescription : null;
        },
    },
    actions: {
        setEditForm({commit}, payload) {
            commit("SET_EDIT_FORM", payload);
        },
    },
    getters: {
        formId: (state) => state.formId
    },
};
