import i18n from "@/i18n/i18n";

export default {
    namespaced: true,
    state: {
        allMenus: [],
    },

    mutations: {
        SET_ALL_MENUS(state, payload) {
            state.allMenus = payload.map((x) => {
                return {...x, name: i18n.t(x.name.toString())};
            });
        },
    },

    actions: {
        setAllMenus({commit}, payload) {
            commit("SET_ALL_MENUS", payload);
        },
    },
    getters: {
        getAllMenus: (state) => state.allMenus,
    },
};
