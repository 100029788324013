export default {
    namespaced: true,
    state: {
        actionOrder: [],
    },

    mutations: {
        UPDATE_ACTION_ORDER(state, payload) {
            state.actionOrder = payload;
        },
        CLEAR_ACTION_ORDER(state) {
            state.actionOrder = [];
        },
    },

    actions: {
        clearActionOrder({commit}) {
            commit('CLEAR_ACTION_ORDER');
        },

        async updateActionOrderWithRepo({commit}, repo) {
            commit('CLEAR_ACTION_ORDER');
            const {data} = await repo;
            await commit('UPDATE_ACTION_ORDER', data.content);
        },

        async updateActionOrderWithData({commit}, data) {
            commit('CLEAR_ACTION_ORDER');
            await commit('UPDATE_ACTION_ORDER', data);
        },
    },

    getters: {
        getActionOrder: (state) => state.actionOrder,
    }
};
