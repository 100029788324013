import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#367588",
                secondary: "#911f1f",
                accent: "#3d8f4c",
                greyish: "#000000",
                error: "#F62D51",
                info: "#367570",
                success: "#36BEA6",
                warning: "#FB8C00",
                blue: "#2e4b93",
            },
        },
    },
});
