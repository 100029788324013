import axiosClient from "@/service/client/AxiosClientToken";
import {create, downloadCsv, get, getForm, remove, update, uploadCsv,} from "@/service/Services";

const resource = "/common/forms";

export default {
    get: (params) => get(axiosClient, params, resource),
    getForm: (id) => getForm(axiosClient, id, resource),
    create: (payload) => create(axiosClient, payload, resource),
    update: (payload, id) => update(axiosClient, payload, id, resource),
    delete: (id) => remove(axiosClient, id, resource),
    downloadCsv: (id) => downloadCsv(axiosClient, id, resource),
    uploadCsv: (id, formData) => uploadCsv(axiosClient, id, formData, resource),
};
