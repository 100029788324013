import axios from "axios";

export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null,
        },
        options: [],
        filters: [],
        pageChanged: false, //in case of deleting the last row in a page
        approveTabs: [],
        excludeRouter: [
            {
                main: 'showConfigureApplications',
                internal: [
                    'showApplicationMandatory',
                    'showConfigureApplicationsActions',
                    'showConfigureApplicationsSetup'
                ],
            },
            {
                main: 'viewPersons',
                internal: [
                    'editPersons'
                ],
            },
            {
                main: 'viewReport',
                internal: [
                    'viewReportParams',
                    'viewReportRole'
                ],
            },
        ],
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar,
            };
        },

        setFilters(state, filters) {
            state.filters = filters;
        },

        setOptions(state, payload) {
            state.options = payload;
        },

        changePage(state, pageChanged) {
            state.pageChanged = pageChanged;
        },

        setApproveTabs(state, approveTabs) {
            state.approveTabs = approveTabs;
        },
    },
    actions: {
        toggleSnackbar({commit}, payload) {
            commit("toggleSnackbar", payload);
        },

        setOptions({commit, state}, payload) {
            commit("setOptions", payload);
        },
        setFilters({commit, state}, payload) {
            commit("setFilters", payload);
        },

        setApproveTabs({commit}) {
            axios.get(`./external/viewApproveTabs.json`).then((response) => {
                commit("setApproveTabs", response.data);
            });
        },
    },
    getters: {
        getOptions: (state) => state.options,
        getFilters: (state) => state.filters
    },
};
