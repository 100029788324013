import axios from "@/service/client/AxiosClient";
import Repository from "../../service/Repository";
import {isEmpty} from "@/util/helper/Utils";

const AuthRepo = Repository.get("auth");
const PersonAuthDocRepo = Repository.get("personAuthDoc");
const getDefaultState = () => {
    return {
        token: null,
        userInfo: [],
        selectedRole: null,
        selectedSystemRole: null,
        taxis: null,
        isTaxis: false,
    }
}

export const namespaced = true
export const state = getDefaultState()
export const mutations = {
    LOGIN(state, payload) {
        state.token = payload.token;
        state.userInfo = payload;
        state.selectedRole = payload.person?.selectedRole?.id;
        state.selectedSystemRole = payload.person?.selectedRole?.systemRole;
        state.isTaxis = false;
    },

    REFRESH_TOKEN(state, refreshToken) {
        state.token = refreshToken;
        if (!isEmpty(state.userInfo)) {
            state.userInfo.token = refreshToken;
        }
    },

    LOGOUT(state) {
        Object.assign(state, getDefaultState())
    },

    SET_SELECTED_ROLE(state, role) {
        state.selectedRole = role.roleId;
        state.selectedSystemRole = role.name;
    },
    SET_AMHNA_METH(state, data) {
        if (!isEmpty(state.userInfo)) {
            state.userInfo.person.amhnaCalc = data?.amhna;
            state.userInfo.person.methCalc = data?.meth;
        }

    },
    SET_TAXIS(state, payload) {
        state.taxis = payload.taxis;
        state.isTaxis = true;
        state.token = payload.token;
    },

    UPDATE_DYNAMIC_VIEW(state, payload) {
        state.dynamicView = payload;
    },
}

export const actions = {
    async login({commit, dispatch}, credentials) {
        // try {
        const authResponse = await AuthRepo.loginLdap(credentials);
        await commit("LOGIN", authResponse.data);
        const authDocResponse = await PersonAuthDocRepo.get(this.params = {
            page: 0,
            limit: null,
            personId: authResponse.data.person.id,
            order: "id desc"
        })
        let authData = authDocResponse.data.content.filter(row => row.type === 6 || row.type === 5)
        let data = {}
        if (!isEmpty(authData)) {
            if (!isEmpty(authData.filter(row => row.type === 6))) {
                data.amhna = authData.filter(row => row.type === 6)[0].docNumber
            }
            if (!isEmpty(authData.filter(row => row.type === 5))) {
                data.meth = authData.filter(row => row.type === 5)[0].docNumber
            }
        }
        await commit("SET_AMHNA_METH", data);
        return authResponse
    },

    async refreshToken({commit}) {
        try {
            const res = await AuthRepo.refreshToken();
            const token = res.data.token;
            commit("REFRESH_TOKEN", token);
        } catch (e) {
            throw e;
        }
    },

    logout({state, commit}) {
        if (state.isTaxis) {
            commit("LOGOUT");
            window.location.href = `https://www1.gsis.gr/oauth2server/logout/${process.env.VUE_APP_TAXIS_CLIENT_ID}/?url=${process.env.VUE_APP_BASE_UI_URL}`;
        } else {
            commit("LOGOUT");
        }
    },

    async setSelectedRole({commit}, payload) {
        try {
            const res = await AuthRepo.changeRole({
                params: {
                    newRoleId: payload.roleId,
                },
            });
            let token = res.data.token;
            commit("REFRESH_TOKEN", token);
            commit("SET_SELECTED_ROLE", payload);
        } catch (e) {
            throw e;
        }
    },

    loginWithTaxis({commit}, payload) {
        commit("LOGIN", payload.login);
        commit("SET_TAXIS", payload.taxis);
    },

}
export const getters = {
    isLoggedIn: (state) => !!state.token,
    getToken: (state) => state.token,
    getStaticList: (state) => state.userInfo?.staticView,
    getPerson: (state) => state.userInfo?.person,
}

