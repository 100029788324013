import axiosClient from "@/service/client/AxiosClientToken";

const resource = "/common/action";

export default {
    get: () => {
        return axiosClient.get(`${resource}/list`);
    },

    getOrder: (commonApplicationActionId) => {
        return axiosClient.get(`${resource}/order/${commonApplicationActionId}`);
    },
};
