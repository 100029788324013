export default {
    namespaced: true,
    state: {
        allRoles: [],
    },

    mutations: {
        SET_ALL_ROLES(state, payload) {
            state.allRoles = payload;
        },
    },

    actions: {
        setAllRoles({commit}, payload) {
            commit("SET_ALL_ROLES", payload);
        },
    },
    getters: {
        getAllRoles: (state) => state.allRoles,
    },
};
